import { render, staticRenderFns } from "./set_bus_user.vue?vue&type=template&id=2576a156&scoped=true&"
import script from "./set_bus_user.vue?vue&type=script&lang=js&"
export * from "./set_bus_user.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2576a156",
  null
  
)

export default component.exports